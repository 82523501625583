import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Link, useLocation, Redirect, useHistory } from 'react-router-dom';
import API from '../utils/api/API';
import Users from '../utils/api/Users';
import usePopup from '../utils/hooks/usePopup';
import CreateAuction from './popups/CreateAuction';
import CreateCustomer from './popups/customers/CreateCustomer';
import CreateTrustee from './popups/customers/CreateTrustee';
import CreateSeller from './popups/sellers/CreateSeller';
import Icon from 'src/elements/Icon';

export default function Navbar() {
	let history = useHistory();

	const mainMenu = [
		{
			path: '/',
			icon: 'home',
			title: 'Hem',
		},
		{
			path: '/users',
			icon: 'groups',
			title: 'Användare',
		},
		{
			path: '/customers',
			icon: 'handshake',
			title: 'Uppdragsgivare',
		},
		{
			path: '/sellers',
			icon: 'task_alt',
			title: 'Projekt',
		},
		{
			path: '/invoices',
			icon: 'request_quote',
			title: 'Fakturor',
		},
		{
			path: '/objects',
			icon: 'warehouse',
			title: 'Objekt',
		},
		{
			path: '/auctions',
			icon: 'calendar_today',
			title: 'Auktioner',
		},
		{
			path: '/lists',
			icon: 'list',
			title: 'Listor',
		},
		{
			path: '/employees',
			icon: 'dashboard',
			title: 'Anställda',
			permissions: [ 'employee_admin' ]
		},
		{
			path: '/expenses',
			icon: 'receipt',
			title: 'Utlägg',
			permissions: [ 'employee_admin' ]
		},
		{
			path: '/customer-invoices',
			icon: 'payments',
			title: 'Kundfakturor',
			permissions: [ 'employee_admin' ]
		},
		// {
		//     path: '/developer',
		// 	icon: 'developer_mode',
		//     title: 'Utvecklings admin',
		//     permissions: [ 'developer' ],
		// },
	];

	const [open] = usePopup();

	const [user, setUser] = useState();

	useEffect(() => {
		(async () => {
			let user = await Users.get(API.getUserData()?.id);
			setUser(user);
		})();

		function findAncestor(el, sel) {
			while ((el = el.parentElement) && !(el.matches || el.matchesSelector).call(el, sel));
			return el;
		}

		window.addEventListener('click', function (e) {
			let clickedOnDropdown = e.target == dropdown.current || dropdown.current == findAncestor(e.target, '.account');
			if (!clickedOnDropdown) setShowAccount(false);

			clickedOnDropdown = e.target == dropdownAdd.current || dropdownAdd.current == findAncestor(e.target, '.add');
			if (!clickedOnDropdown) setShowAdd(false);
		});
	}, []);

	const dropdown = useRef();
	const dropdownAdd = useRef();
	const [showAccount, setShowAccount] = useState(false); // Show the account dropdown or not.
	const [showAdd, setShowAdd] = useState(false); // Show the add dropdown or not.

	const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

	let location = useLocation();

	const [redirect, setRedirect] = useState(false);
	if (redirect) return <Redirect to={redirect}></Redirect>;

	return (
		<NavbarStyle className={`navbar ${mobileMenuOpen ? 'mobile-open' : ''}`}>

			<div id="menubutton" onClick={() => setMobileMenuOpen(!mobileMenuOpen)}>
				<div></div>
				<div></div>
				<div></div>
			</div>

			<nav>
				{mainMenu &&
					mainMenu
						.filter((item) => (item.permissions ? API.lockTo(item.permissions) : true))
						.map((item, index) => (
							<Link key={index} to={item.path} className={`menu-item ${item.path == location.pathname ? 'current' : ''}`} onClick={() => setMobileMenuOpen(false)}>
								<Icon>{item.icon}</Icon>
								<span>{item.title}</span>
							</Link>
						))}
			</nav>

			<div className="actions">
				<Add ref={dropdownAdd} className={`add ${showAdd ? 'open' : 'closed'}`}>
					<span onClick={() => setShowAdd(!showAdd)}></span>
					<Dropdown className={`dropdown ${showAdd ? 'visible' : 'hidden'}`}>
						<h4 className="menu-item nolink">Skapa ny</h4>
						<div className="spacer xs"></div>
						<div
							className="menu-item"
							onClick={() => {
								setShowAdd(!showAdd);
								open(<CreateCustomer />);
							}}
						>
							Uppdragsgivare
						</div>
						<div
							className="menu-item"
							onClick={() => {
								setShowAdd(!showAdd);
								open(<CreateTrustee />);
							}}
						>
							Förvaltare
						</div>
						<div
							className="menu-item"
							onClick={() => {
								setShowAdd(!showAdd);
								open(<CreateSeller />);
							}}
						>
							Projekt
						</div>
						<div className="spacer xs"></div>
						<div
							className="menu-item"
							onClick={() => {
								setShowAdd(!showAdd);
								open(<CreateAuction />);
							}}
						>
							Auktion
						</div>
						<div className="spacer xs"></div>
						<div
							className="menu-item"
							onClick={() => {
								setShowAdd(!showAdd);
								history.push('/mail-template');
							}}
						>
							Mejlutskick
						</div>
					</Dropdown>
				</Add>

				<div className="account" ref={dropdown} style={{ background: `#eee` }}>
					<span onClick={() => setShowAccount(!showAccount)}>
						{user && user.first_name[0]}
					</span>
					<Dropdown className={`dropdown ${showAccount ? 'visible' : 'hidden'}`}>
						<h4 className="menu-item">
							<Link to={`/me`} onClick={() => setShowAccount(false)}>
								{API.getUserData().first_name} {API.getUserData().last_name}
							</Link>
						</h4>

						<div className="menu-item">
							<Link to={`/me/time`} onClick={() => setShowAccount(false)}>
								Tidsrapportering
							</Link>
						</div>

						<div className="menu-item"></div>

						<div
							className="menu-item"
							onClick={() => {
								API.clearCache();
								setShowAccount(false);
								history.go(0);
							}}
						>
							Rensa cache
						</div>

						<div
							className="menu-item"
							onClick={() => {
								localStorage.removeItem('user');
								localStorage.removeItem('usertoken');
								API.clearCache();
								setRedirect('/login');
							}}
						>
							Logga ut
						</div>
					</Dropdown>
				</div>
			</div>
		</NavbarStyle>
	);
}

const Add = styled.div`
	width: 2em;
	height: 2em;
	position: relative;
	border-radius: 10em;
	margin-right: 1em;
	align-self: center;

	.dropdown {
		right: -1em;
	}

	& > span {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		cursor: pointer;
	}

	&:after,
	&:before {
		pointer-events: none;
		content: '';
		position: absolute;
		background: var(--color-background-2);
		top: 50%;
		left: 50%;
		height: 0.2em;
		width: 50%;
		transform: translate(-50%, -50%) rotate(90deg);
		border-radius: 10em;
		transition: transform 300ms;
	}

	&:after {
		transform: translate(-50%, -50%) rotate(0deg);
	}

	&.open {
		&:before {
			transform: translate(-50%, -50%) rotate(45deg);
		}
		&:after {
			transform: translate(-50%, -50%) rotate(-45deg);
		}
	}
`;

const Dropdown = styled.div`
	background: var(--color-background-2);
	padding: 1.5em;
	border-radius: 0.5em;
	box-shadow: var(--shadow-1);
	display: flex;
	flex-direction: column;
	position: absolute;
	bottom: -1.5em;
	right: -0.5em;
	z-index: 100;
	color: white;
	width: max-content;
	min-width: 10em;

	pointer-events: none;
	transition: opacity 300ms, transform 300ms;

	transform: translateY(90%);
	opacity: 0;

	&:after {
		content: '';
		position: absolute;
		top: 0;
		right: 0.5em;
		width: 1.5em;
		height: 1.5em;
		background: var(--color-background-2);
		transform: translate(-50%, -50%) rotate(45deg);
		box-shadow: var(--shadow-1);
		border-radius: 0.1em;
		z-index: -1;
	}

	.menu-item {
		min-height: 1rem;
		color: white;
		padding: 0.1em;

		* {
			color: white;
			text-decoration: none;
		}

		&:not(.nolink) {
			cursor: pointer;

			&:hover {
				text-decoration: underline;
			}
		}
	}

	h4 {
		font-weight: 600;
	}

	&.visible {
		pointer-events: all;
		transition: opacity 300ms, transform 300ms;

		transform: translateY(100%);
		opacity: 1;
	}
`;

const NavbarStyle = styled.section`
	z-index: 1001;
	position: sticky;
	top: 0;

	.actions {
		display: flex;
		align-items: center;
		margin-left: auto;

		.account {
			margin-right: 1em;
			width: 3em;
			height: 3em;
			background-position: center;
			background-size: cover;
			border-radius: 100em;
			position: relative;

			& > span {
				border-radius: 100em;
				width: 100%;
				height: 100%;
				cursor: pointer;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 1.25em;
				font-weight: 600;
				color: #3d3d3d;
			}
		}
	}

	display: flex;
	background: white;
	width: 100%;
	box-shadow: var(--shadow-1);
	padding: 0rem 1rem;

	nav {
		display: flex;
		overflow: auto;

		.menu-item {
			padding: 2rem 1rem;
			cursor: pointer;
			color: black;
			text-decoration: none;
			position: relative;
			display: flex;
			align-items: center;
			gap: 0.5rem;
			
			.icon {
				color: #3d3d3d;
				font-size: 1.3em;
			}

			&.current:after {
				content: '';
				position: absolute;
				bottom: 0;
				left: 0;
				width: 100%;
				height: 0.2em;
				background: var(--color-primary);
			}
		}
	}

	body.mobile &,
	body.tablet & {
		height: 70px;

		.account {
			margin-right: 0;
		}

		#menubutton {
			font-size: 32px;
			flex-shrink: 0;
			align-self: center;
			width: 1em;
			height: 1em;
			/* outline: dashed 1px red; */
			display: flex;
			flex-direction: column;
			justify-content: space-around;

			& > div {
				width: 100%;
				height: 2px;
				background: black;
				border-radius: 0.5em;
			}
		}

		nav {
			position: absolute;
			z-index: 110;
			top: 100%;
			left: 0;
			right: 0;
			background: white;
			flex-direction: column;
			height: calc(100dvh - 70px);
			border-top: solid 1px #ddd;
			display: none;

			.menu-item {
				padding: 1em;
				width: 100%;
				text-align: left;
				gap: 1.25em;

				.icon {
					font-size: 1.5em;
				}

				&.current {
					font-weight: bold;
					background: var(--color-primary);

					&:after {
						content: unset;
					}
				}
			}
		}

		&.mobile-open {
			#menubutton {
				outline-width: 4px;

				& > div {
					&:nth-child(1) {
						transform: translateY(0.345em) rotate(45deg);
					}
					&:nth-child(2) {
						opacity: 0;
					}
					&:nth-child(3) {
						transform: translateY(-0.345em) rotate(-45deg);
					}
				}
			}
			nav {
				display: flex;
			}
		}
	}

	@media print {
		& {
			display: none;
		}
	}
`;
