import React, { useContext, useEffect, useState } from 'react';
import Page from '../../layout/Page';
import Card from '../../layout/Card';
import styled from 'styled-components';
import AuctionSelect from '../../components/auction/AuctionSelect';
import { useHistory } from 'react-router-dom';
import PNTrading from 'src/pn-trading-sdk';
import { formatPrice } from '../../utils/Util';
import Objects from '../../utils/api/Objects';
import useSessionState from '../../utils/hooks/useSessionState';

export function getUsernameByObject(object) {
	return object.ending_data ? (object.ending_data.lastBid.username ?? object.leading_username) : undefined
}

// Sorts the objects by username.
export const sortByWinningUser = (a, b) => {
	return ('' + (a.ending_data ? getUsernameByObject(a) : 'öööööööööööööööööööö')).localeCompare(b.ending_data ? getUsernameByObject(b) : 'ö');
};

export default function PageTickOff(props) {
	let id = props.match.params.id;

	const [sortBy, setSortBy] = useSessionState('tickoff_sort', 'user');

	const [objects, setObjects] = useState();
	const [auction, setAuction] = useState();
	const [invoiceObjects, setInvoiceObjects] = useState([]);

	useEffect(() => {
		PNTrading.get(`/auctions/${id}?embed=true&noCache=true`).then(setAuction);
		const fetchObjects = () => {
			PNTrading.get(`/auctions/${id}/objects?limit=10000&embed=true&noCache=true`).then((resp) => setObjects(resp.data));
			PNTrading.get(`/payments?user=all&auction=${id}&limit=10000`).then((resp) => setInvoiceObjects(resp.data.flatMap((i) => i.objects.map((o) => ({ ...o, status: i.status })))));
		};

		fetchObjects();
		let intervalID = setInterval(fetchObjects, 5000);
		return () => clearInterval(intervalID);
	}, [id]);

	const updatePickStatus = (id, status) => {
		PNTrading.put(`/objects/${id}`, { tickoff: status }, true);
		setObjects((objects) => {
			let newobjects = objects.map((object) => {
				if (object.id == id) {
					let tmp = { ...object };
					tmp.tickoff = status;
					return tmp;
				} else {
					return object;
				}
			});
			return newobjects;
		});
	};

	function sort(a, b) {

		if (sortBy == 'object') {
			return a.position - b.position;
		}

		return sortByWinningUser(a, b)
	}

	if (!objects || !auction)
		return (
			<div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
				<div className="loader"></div>
			</div>
		);

	return (
		<Style>
			<Card size="full">
				<div className="title-row flex row center space-between">
				<h2>
					Avprickning för <strong>{auction.name}</strong>
				</h2>
				<select
					name="sort-by"
					value={sortBy}
					onChange={(e) => setSortBy(e.target.value)}
				>
					<option value="user">Sortera efter användare</option>
					<option value="object">Sortera efter objekt ID</option>
				</select>
				</div>
				<div className="spacer m"></div>

				<div className="objects">
					{objects.sort(sort).map((object) => {
						return (
							<div className={`object ${object.tickoff == true ? 'picked' : ''} ${!object.ending_data ? 'nobids' : ''}`}>
								{object.ending_data &&
									{
										processing: <div className="badge red">EJ BETALD</div>,
										paid: <div className="badge green">BETALD</div>,
										canceled: <div className="badge gray">AVBRYTEN</div>,
										undefined: <div className="badge red">EJ BETALD</div>,
									}[invoiceObjects.find((o) => o.id == object.id)?.status]}
								<img src={object.embed.thumbnail?.sizes?.['500x500']} alt="" />
								<div className="content">
									<h2># {object.position}</h2>
									<h4>{object.title}</h4>
									<p>
										<b>Köpare:</b> {object.ending_data ? getUsernameByObject(object) : ' - '}
									</p>
									<p>
										<b>Bud:</b> {object.ending_data ? formatPrice(object.ending_data.lastBid.amount) : ' - '}
									</p>

									<select name="tick-off-status" value={object.tickoff == true ? 'yes' : 'no'} onChange={(e) => updatePickStatus(object.id, e.target.value == 'yes' ? true : false)}>
										<option value="no">Ej hämtad</option>
										<option value="yes">Hämtad</option>
									</select>
								</div>
							</div>
						);
					})}
				</div>
			</Card>
		</Style>
	);
}

const Style = styled(Page)`

	.title-row {
		.mobile & {
			flex-direction: column;
			align-items: stretch;
			gap: 1rem;

			h2 {
				font-size: 1rem;
			}
		}
	}

	.objects {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		gap: 1rem;

		.object {
			padding: 1rem;
			border-radius: 1rem;
			position: relative;

			&.nobids {
				opacity: 0.1;
				pointer-events: none;
			}

			&.picked {
				border-color: #21ab21;
				background-color: #d7ecd7;
			}

			.badge {
				position: absolute;
				top: 1.5rem;
				left: 1.5rem;
			}

			img {
				width: 100%;
				height: auto;
				object-fit: cover;
				border-radius: 1rem;
			}

			.content {
				padding-top: 1rem;

				h4 {
					margin-bottom: 0.5rem;
				}

				select {
					margin-top: 0.5rem;
					width: 100%;
				}
			}
		}
	}

	.tablet & {
		.objects {
			grid-template-columns: repeat(3, 1fr);
		}
	}

	.mobile & {
		.objects {
			grid-template-columns: repeat(2, 1fr);
			gap: 0.5rem;

			.object {
				padding: 0.5rem;
				border-radius: 0.5rem;

				.badge {
					top: 0.75rem;
					left: 0.75rem;

					font-size: 0.75rem;

					padding: 0.25rem 0.5rem;
					border-radius: 0.5rem;
				}

				img {
					border-radius: 0.5rem;
				}

				.content {

					h2 {
						font-size: 1rem;
					}

					h4 {
						margin-bottom: 0.25rem;
						font-size: 0.75rem;
					}

					p {
						font-size: 0.75rem;
					}

					select {
						padding: 0.25rem 0.5rem;
					}
				}
			}
		}
	}
`;
