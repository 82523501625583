import PNTrading from "src/pn-trading-sdk";
import Icon from "../../elements/Icon";

export default function TransferObjectButton({ invoice, object }) {

    async function handleClick() {
        let newInvoiceID = Number(prompt("Ange fakturanummer att flytta till"));

        if (newInvoiceID == 0) return;

        if (isNaN(newInvoiceID)) {
            alert("Ogiltigt fakturanummer");
            return;
        }

        if (newInvoiceID == invoice.id) {
            alert("Du kan inte flytta objekt till samma faktura");
            return;
        }

        try {
            await PNTrading.put(`/payments/transfer-object/${invoice.id}/${newInvoiceID}/${object.id}`);
            alert('Objektet överfördes');
            window.location.reload();
        } catch (error) {

            if (error.response == 'Object not found') {
                alert('Objektet kunde inte hittas');
                return;
            }

            if (error.response == 'Payment not found') {
                alert('Fakturan kunde inte hittas');
                return;
            }

            alert('Ett fel uppstod');
            console.error(error);
        }

    }

    return (
        <Icon onClick={handleClick}>swap_horiz</Icon>
    )
}