import PNTrading from 'src/pn-trading-sdk';
import React, { useState } from 'react';
import Icon from '../../elements/Icon';

export default function InvoiceSendReceiptButton({ invoice }) {
	const [loading, setLoading] = useState(false);

	return !invoice ? null : (
		<div
			className="icon-button"
			onClick={async () => {
				if (!invoice.isValid()) {
					alert('Fakturan saknar information');
					return;
				}

				setLoading(true);
				let resp = await PNTrading.get(`/payments/${invoice.id}?user=all&email=true`);
				setLoading(false);

				if (resp.success == false) {
					alert('Error: ' + resp.error);
					return;
				}
			}}
		>
			<Icon>mail</Icon>
			{loading && <div className="loader" style={{ fontSize: '1.2rem', marginLeft: '2rem', marginRight: '2rem' }}></div>}
			{!loading && <span>Skicka kvitto</span>}
		</div>
	);
}
