import React, { useEffect, useState } from 'react';
import Page from '../../layout/Page';
import Card from '../../layout/Card';
import Checkbox from '../../elements/Checkbox';
import styled from 'styled-components';
import PNTrading from 'src/pn-trading-sdk';
import { calculateServiceFee, caluclateCommissionAmount, formatPrice, getLocalDate } from '../../utils/Util';
import useSessionState from '../../utils/hooks/useSessionState';

export default function PageProjectSummary(props) {
	let id = props.match.params.id;

	const [objects, setObjects] = useState();
	const [seller, setSeller] = useState();
	const [auctions, setAuctions] = useState();
	const [extraRows, setExtraRows] = useState();

	const [from, setFrom] = useSessionState(`_pn_project_summary_from_${id}`, '');
	const [exclude, setExclude] = useSessionState(`_pn_project_summary_exclude_${id}`, []);
	const [to, setTo] = useSessionState(`_pn_project_summary_to_${id}`, new Date().getDateString());
	const [limit, setLimit] = useSessionState(`_pn_project_summary_limit_${id}`, 100);
	const [commission, setCommission] = useSessionState(`_pn_commission_${id}`, 0);
	const [selectedAuction, setSelectedAuction] = useSessionState(`_pn_selected_auction_${id}`, '');
	const [showFee, setShowFee] = useSessionState(`_pn_show_fee_${id}`, false);
	const [noCommission, setNoCommission] = useSessionState(`_pn_no_commission_${id}`, 'no');

	const load = async () => {
		setObjects(undefined);
		let resp = await PNTrading.get(`/objects?priceRange=50,100000000&noCache=true&limit=${limit}&seller=${id}&embed=true&orderBy=position&order=asc`);
		let objects = resp.data.filter((o) => o.status == 'ended');

		let payments = (await PNTrading.get(`/payments?user=all&limit=100000&status=paid`)).data;
		objects = objects.map((o) => {
			let payment = payments.find((p) => p.objects.find((oo) => oo.id == o.id) != undefined);

			let objectPaymentAmount = Number(payment?.objects.find((oo) => oo.id == o.id)?.amount || o.ending_data?.lastBid?.amount);

			return { 
				...o, 
				payment,
				objectPaymentAmount,
				customAmount: objectPaymentAmount
			};
		});

		objects = objects.filter((o) => {
			if (o.payment == undefined) return false;

			if (new Date(o.payment.payed_date).getTime() < new Date(from).getTime()) return false;
			if (new Date(o.payment.payed_date).getTime() > new Date(to).getTime()) return false;

			return true;
		})

		let auctionIDs = [...new Set(objects.map((o) => o.auction))];
		let auctions = await PNTrading.get(`/auctions?embed=true`);
		setAuctions(auctions.filter((a) => auctionIDs.includes(a.id)));

		setObjects(objects);

		let rows = payments
			.filter((payment) => {

				if (new Date(payment.payed_date).getTime() < new Date(from).getTime()) return false;
				if (new Date(payment.payed_date).getTime() > new Date(to).getTime()) return false;

				return true;
			})
			.flatMap(p => p.rows)
			.filter(p => {
				if (p.title == 'Serviceavgift') return false;
				if (p.seller != id) return false;
				return true;
			})
			.map(p => {
				return {
					title: p.title.toLowerCase(),
					amount: Number(p.amount)
				}
			})

		let uniqueRows = {};
		rows.forEach(r => {
			if (uniqueRows[r.title] == undefined) uniqueRows[r.title] = 0;
			uniqueRows[r.title] += r.amount;
		})

		setExtraRows(Object.entries(uniqueRows));
	};

	useEffect(() => {
		load();
		PNTrading.get(`/sellers/${id}?embed=true&noCache=true`).then(setSeller);
	}, [id]);

	if (!seller)
		return (
			<div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
				<div className="loader"></div>
			</div>
		);

	let filteredObjects = objects?.filter((o) => {
		if (o.payment == undefined) return false;
		if (selectedAuction != '') return o.auction == selectedAuction;
		return true;
	});

	let billableObjects = filteredObjects?.filter((o) => {
		if (exclude.includes(o.id)) return false
		return true;
	})

	let subtotalBids = billableObjects ? billableObjects.reduce((tot, o) => tot + o.objectPaymentAmount, 0) : 0;

	if (extraRows) {
		extraRows.forEach(row => {
			if (exclude.includes(row[0])) return;
			subtotalBids += row[1];
		})
	}

	let nonTaxableSubTotal = billableObjects ? billableObjects.reduce((tot, o) => (o.no_tax == 'YES' ? tot + Number(o.leading_bid) : tot), 0) : 0;

	let taxBids = (subtotalBids - nonTaxableSubTotal) * 0.25;
	let totalBids = Math.floor(subtotalBids + taxBids);

	let commissionAmount = noCommission == 'yes' ? 0 : caluclateCommissionAmount(billableObjects, commission / 100);

	if (extraRows && noCommission != 'yes') {
		extraRows.forEach(row => {
			if (exclude.includes(row[0])) return;
			console.log(row)
			commissionAmount += row[1] * (commission / 100);
		})
	}

	let commissionTax = commissionAmount * 0.25;
	let commissionTotal = Math.floor(commissionAmount + commissionTax);

	let serviceFee = calculateServiceFee(billableObjects);
	let serviceFeeTax = serviceFee * 0.25;
	let serviceFeeTotal = Math.floor(serviceFee + serviceFeeTax);

	let toBeReceived = totalBids - commissionTotal;

	return (
		<Style>
			<Card size="medium" className="hide-print">
				<h2>Projektsummering</h2>
				<h5>(denna ruta visas inte vid utskrift)</h5>
				<div className="spacer s"></div>
				<p>Denna sida kan skrivas ut direkt i webbläsaren, antingen som en PDF eller via skrivare. Provisionen för projektet kan justeras här innan utskrift, eventuella kostnader på objekten kommer räknas av EFTER provision.</p>
				<div className="spacer s"></div>

				<div className="row">
					<div className="column">
						<label htmlFor="commission">Antal objekt</label>
						<div className="spacer xs"></div>
						<input type="number" style={{width: 100}} value={limit} min={0} max={5000} onChange={(e) => setLimit(e.target.value ?? 0)} name="commission" placeholder="Antal objekt" />
					</div>
				</div>

				<div className="spacer s"></div>

				<div className="row" style={{ alignItems: 'flex-end' }}>
					<div className="column">
						<label htmlFor="commission">Från</label>
						<div className="spacer xs"></div>
						<input type="date" name="from" value={from} onChange={(e) => setFrom(e.target.value)} />
					</div>

					<div className="spacer ws"></div>

					<div className="column">
						<label htmlFor="commission">Till</label>
						<div className="spacer xs"></div>
						<input type="date" name="from" value={to} onChange={(e) => setTo(e.target.value)} />
					</div>

					<div className="spacer ws"></div>

					<button onClick={() => load()}>Filtrera</button>
				</div>

				<div className="spacer s"></div>

				<div className="row">
					<div className="column">
						<label>Välj en auktion</label>
						<div className="spacer xs"></div>
						<select onChange={(e) => setSelectedAuction(e.target.value)} value={selectedAuction}>
							<option value="">Välj en auktion</option>
							{auctions &&
								auctions.map((a) => (
									<option key={a.id} value={a.id}>
										{a.id} - {a.name}
									</option>
								))}
						</select>
					</div>

					<div className="spacer ws"></div>

					<div className="column">
						<label>Inkludera serviceavgift</label>
						<div className="spacer xs"></div>
						<select onChange={(e) => setShowFee(e.target.value)} value={showFee}>
							<option value="false">Nej</option>
							<option value="true">Ja</option>
						</select>
					</div>
				</div>

				<div className="spacer s"></div>

				<div className="row" style={{ alignItems: 'flex-end' }}>
					<div className="column">
						<label htmlFor="commission">Provision (%)</label>
						<div className="spacer xs"></div>
						<input style={{ width: 120 }} type="number" value={commission} min={0} max={100} onChange={(e) => setCommission(e.target.value ?? 0)} name="commission" placeholder="Provision (%)" />
					</div>
					<div className="spacer ws"></div>
					<div className="column">
						<label htmlFor="commission">Utan provision</label>
						<div className="spacer xs"></div>
						<select value={noCommission} onChange={e => setNoCommission(e.target.value)}>
							<option value="no">Nej</option>
							<option value="yes">Ja</option>
						</select>
					</div>
					<div className="spacer ws"></div>
					<button onClick={() => window.print()} disabled={billableObjects?.length == 0}>Skriv ut</button>
				</div>
			</Card>

			<Card size="full" className="page-card">
				{filteredObjects && auctions && seller ? (
					<>
						<div className="header">
							<div className="logo">
								<img src="/ra-logga-invoice.png" alt="" />
							</div>

							<div className="document-info">
								<p>
									<b>{seller.name}</b> ({seller.org_number})
								</p>
								<p>
									<b>Referens:</b> {seller.trustee?.name}
								</p>
								<p>{seller.trustee?.email}</p>
							</div>
						</div>

						<div className="spacer l"></div>

						<div className="summary-text">
							<p className="small-title metropolis">sålda objekt</p>
							<div className="spacer xs"></div>
							<h1 className="title">Sammanställning</h1>
							<div className="spacer s"></div>
							<p className="description">Tack för du väljer att sälja med oss på Riksauktioner. Här kommer din auktionssammanställning med information om dina sålda objekt och utbetalning. Har du några frågor är du varmt välkommen att kontakta oss på: ekonomi@riksauktioner.se</p>
						</div>

						<div className="spacer l"></div>

						<div className="summary-row">
							<div className="column summary">
								<p className="small-title">summa total försäljning</p>
								<div className="spacer xs"></div>
								<div className="box">
									<p>
										Summa: <b>{formatPrice(subtotalBids)}</b>
									</p>
									<p>
										Moms: <b>{formatPrice(taxBids)}</b>
									</p>
									<p>
										Totalt ink. moms: <b>{formatPrice(totalBids)}</b>
									</p>
								</div>
							</div>

							<div className="column commission">
								<p className="small-title">provision</p>
								<div className="spacer xs"></div>
								<div className="box">
									<p>
										Provision: <b>{formatPrice(commissionAmount.toFixed(2))}</b> ({commission}%)
									</p>
									<p>
										Moms: <b>{formatPrice(commissionTax.toFixed(2))}</b>
									</p>
									<p>
										Totalt ink. moms: <b>{formatPrice(commissionTotal)}</b>
									</p>
								</div>
							</div>

							{showFee === 'true' && (
								<div className="column fee">
									<p className="small-title">serviceavgift</p>
									<div className="spacer xs"></div>
									<div className="box">
										<p>
											Serviceavgift: <b>{formatPrice(serviceFee.toFixed(2))}</b>
										</p>
										<p>
											Moms: <b>{formatPrice(serviceFeeTax.toFixed(2))}</b>
										</p>
										<p>
											Totalt ink. moms: <b>{formatPrice(serviceFeeTotal)}</b>
										</p>
									</div>
								</div>
							)}
						</div>

						<div className="spacer m"></div>

						<div className="to-be-recived-box">
							<p className="small-title">insättning</p>
							<div className="spacer s"></div>
							<p className="italics">
								Insättning sker <b>14</b> bankdagar efter avslutad auktion.
							</p>
							<div className="spacer s"></div>
							<p>
								Summa: <b>{formatPrice(toBeReceived)}</b>
							</p>
							{seller.bank_account_number && (
								<p>
									Insättning sker till konto: <b>{seller.bank_account_number}</b>
								</p>
							)}
						</div>

						<div className="spacer m"></div>

						<p className="small-title">dina sålda objekt</p>
						<div className="spacer xs"></div>
						<table className="objects-table" cellSpacing={0}>
							<thead>
								<tr>
									<th></th>
									<th></th>
									<th>Betaldatum</th>
									<th>Vinnande bud</th>
									<th className="hide-print exclude-checkbox">
										Exkludera
										<div className="spacer wxs"></div>
										<button onClick={() => setExclude([])}>Rensa</button>
									</th>
								</tr>
							</thead>

							<tfoot>
								<tr>
									<td style={{ height: '2rem' }}></td>
								</tr>
							</tfoot>
							<tbody>
								{filteredObjects.map((object) => (
									<tr key={object.id} title={JSON.stringify(object, null, 4)} className={`${exclude.includes(object.id) ? 'excluded hide-print' : ''}`}>
										<td className="image">
											<img src={object.embed?.thumbnail?.sizes?.['150x150']} />
										</td>
										<td className="title">
											<div className="id">
												ID: {object.position} / {object.id}
											</div>
											<div className="title">
												<b>{object.title}</b>
											</div>
										</td>
										<td className="ended">
											<b>{getLocalDate(object.payment.payed_date).getDateString()}</b>
										</td>
										<td className="bid">
											<b>{formatPrice(object.objectPaymentAmount)}</b>
										</td>
										<td className="hide-print exclude-checkbox">
											<Checkbox 
												checked={exclude.includes(object.id)}
												onChange={e => {
													if (e.target.checked) return setExclude(e => ([...e, object.id]))
													setExclude(e => e.filter(id => id !== object.id))
												}}
											/>
										</td>
									</tr>
								))}
								{extraRows?.map((row) => (
									<tr key={row[0]} className={`${exclude.includes(row[0]) ? 'excluded hide-print' : ''}`}>
										<td className="image">
										</td>
										<td className="title">
											<div className="id">
											</div>
											<div className="title">
												<b>{row[0]}</b>
											</div>
										</td>
										<td className="ended">
										</td>
										<td className="bid">
											<b>{formatPrice(row[1])}</b>
										</td>
										<td className="hide-print exclude-checkbox">
											<Checkbox
												checked={exclude.includes(row[0])}
												onChange={(e) => {
													if (e.target.checked) return setExclude((e) => [...e, row[0]]);
													setExclude((e) => e.filter((id) => id !== row[0]));
												}}
											/>
										</td>
									</tr>
								))}
							</tbody>
						</table>

						<div className="footer">
							<div className="meta-row">
								<p>Riksauktioner AB</p>
								<p>Org. 556236-6954</p>
								<p>Tel: 08 446 17 12</p>
								<p>BG: 5849-4808</p>
								<p>www.riksauktioner.se</p>
							</div>
						</div>
					</>
				) : (
					<>
						<div style={{ width: '100%', height: '300px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
							<div className="loader"></div>
						</div>
					</>
				)}
			</Card>
		</Style>
	);
}

const Style = styled(Page)`
	p {
		font-size: 12px;
	}

	.header {
		display: flex;
		align-items: center;
		justify-content: space-between;

		.logo {
			img {
				width: 6cm;
			}
		}

		.document-info {
			text-align: right;
		}
	}

	.small-title {
		text-transform: uppercase;
		font-size: 10px;
	}

	.summary-text {
		.title {
			font-size: 25.5px;
			font-weight: normal;
		}

		.description {
			font-size: 15px;
			width: 82%;
			line-height: 1.75em;
		}
	}

	.summary-row {
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 2rem;

		.column {
			.box {
				border: solid 1px #f4f4f4;
				border-radius: 10px;
				padding: 0.75rem;

				p {
					&:not(:last-child) {
						margin-bottom: 0.3rem;
					}
				}
			}
		}
	}

	.to-be-recived-box {
		border: solid 1px #f4f4f4;
		border-radius: 10px;
		padding: 0.75rem;
		background-color: #f4f4f4;

		.italics {
			font-style: italic;
		}
	}

	.objects-table {
		width: 100%;

		th {
			padding-top: 1rem;
			font-size: 12px;
			text-align: right;
			font-weight: 200;
			border-bottom: 1px solid #f4f4f4;
		}

		tbody {
			tr {
				td {
					font-size: 15px;
					vertical-align: bottom;
					padding-top: 0.7rem;
					padding-bottom: 0.7rem;
					/* border-top: 1px solid #F4F4F4; */
					border-bottom: 1px solid #f4f4f4;

					&.image {
						padding-bottom: 0.4rem;
						img {
							width: 38px;
							height: 38px;
							border-radius: 5px;
						}
					}

					&.title {
						padding-left: 1rem;

						.id {
							font-size: 10px;
							font-weight: normal;
						}

						.title {
							width: 25rem;
							overflow: hidden;
							text-overflow: ellipsis;
							white-space: nowrap;
							text-transform: capitalize;
						}
					}

					&.ended {
						text-align: right;
					}

					&.bid {
						text-align: right;
					}

					&.exclude-checkbox {
						& > label {
							margin-left: 1rem;
							margin-bottom: 1rem;
						}
					}
				}

				&.excluded {
					opacity: 0.5;
				}
			}
		}
	}

	.footer {
		padding-top: 1rem;

		.meta-row {
			display: flex;
			align-items: center;
			justify-content: center;

			p {
				color: #555555;
				height: 1em;
				line-height: 1em;

				&:not(:last-child) {
					margin-right: 0.5rem;
					padding-right: 0.5rem;
					border-right: solid 1px currentColor;
				}
			}
		}
	}

	@media screen {
		.page-card {
			width: 22cm;
		}

		.mobile & {
			.hide-print {
				width: 100%;
				min-width: 0;

				.row {
					flex-direction: column;
					align-items: stretch !important;

					& > * {
						margin-bottom: 0.5rem;
					}
				}
			}

			.page-card {
				display: none;
			}
		}
	}

	@media print {
		.footer {
			position: fixed;
			bottom: 1rem;
			width: 100%;
		}
	}
`;
