import PNTrading from 'src/pn-trading-sdk';
import React from 'react';
import Icon from '../../elements/Icon';
import usePopup from '../../utils/hooks/usePopup';

export default function InvoicePrintButton({ invoice }) {
	const [open] = usePopup();

	return !invoice ? null : (
		<div
			className="icon-button"
			onClick={async () => {
				if (!invoice.isValid()) {
					alert('Fakturan saknar information');
					return;
				}

				let file = await PNTrading.get(`/payments/${invoice.id}?user=all&print=true`);
				setTimeout(() => {
					if (file.url) {
						window.open(`${PNTrading.getURL()}${file.url}`, '_blank').focus();
					}
				}, 1000);
			}}
		>
			<Icon>print</Icon>
			<span>Skriv ut</span>
		</div>
	);
}
