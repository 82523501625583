import React from 'react';
import { useHistory } from 'react-router-dom';
import Table from 'src/components/Table';
import Icon from 'src/elements/Icon';
import Card from 'src/layout/Card';
import { formatPrice } from 'src/utils/Util';
import { CustomerInvoice, deleteCustomerInvoice } from 'src/utils/api/customer-invoices/CustomerInvoices';
import useCustomerInvoices from 'src/utils/api/customer-invoices/hooks/useCustomerInvoices';
import styled from 'styled-components';

export default function CustomerInvoicesCard() {

	const history = useHistory();

	const { customerInvoices, loading } = useCustomerInvoices();

	function handleEditCustomerInvoiceClick(invoice: CustomerInvoice) {
		return () => {
			history.push(`/customer-invoices/${invoice.id}`);
		}
	}

    function handleRemoveCustomerInvoiceClick(invoice: CustomerInvoice) {
        return () => {
            if (invoice.id !== null && window.confirm('Är du säker på att du vill ta bort denna faktura?')) {
                deleteCustomerInvoice(invoice.id).then(() => {
                    window.location.reload();
                });
            }
        }
    }

    if (loading) {
        return (
			<div style={{ paddingTop: 100, width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
				<div className="loader"></div>
			</div>
        )
    }

    return (
        <Style size="full">
            <div className="header">
                <h1>Kundfakturor</h1>
                <div className="spacer auto"></div>
            </div>

            <div className="spacer m"></div>

            <Table
                data={customerInvoices}
                structure={[
                    {
                        heading: 'Fakturanummer',
                        key: 'id',
                    },
                    {
                        heading: 'Kund',
                        key: 'customerName',
                    },
                    {
                        heading: 'Referens',
                        key: 'customerReference',
                    },
                    {
                        heading: 'Skapad',
                        key: 'created_at',
                        component: (row: CustomerInvoice) => {
                            return row.created_at ? new Date(row.created_at).toLocaleDateString('sv-SE') : '-';
                        },
                    },
                    {
                        heading: 'Skickad',
                        key: 'sentDate',
                        component: (row: CustomerInvoice) => {
                            return (row.status === 'sent' && row.sent_at) ? new Date(row.sent_at).toLocaleDateString('sv-SE', {
                                year: 'numeric',
                                month: '2-digit',
                                day: '2-digit',
                                hour: '2-digit',
                                minute: '2-digit',
                            }) : '-';
                        },
                    },
                    {
                        heading: 'Status',
                        key: 'status',
                        component: (row: CustomerInvoice) => {
                            return {
                                draft: <div className="badge gray">UTKAST</div>,
                                sent: <div className="badge green">SKICKAD</div>,
                            }[row.status]
                        },
                    },
                    {
                        heading: 'Summa',
                        key: 'totalGrossAmount',
                        component: (row: CustomerInvoice) => {
                            return row.totalGrossAmount ? formatPrice(row.totalGrossAmount) : '-';
                        },
                    },
                    {
                        heading: '',
                        className: 'row-icons',
                        component: (row: CustomerInvoice) => {
                            return (
                                <div>
                                    <Icon onClick={handleEditCustomerInvoiceClick(row)}>{row.status === 'sent' ? 'receipt' : 'edit'}</Icon>
                                    {row.status === 'draft' && <Icon onClick={handleRemoveCustomerInvoiceClick(row)}>delete</Icon>}
                                </div>
                            );
                        },
                    },
                ]}
            />

        </Style>
    )
}

const Style = styled(Card)`

    .row-icons {
		div {
			display: flex;
			justify-content: flex-end;
			font-size: 1.5em;

			.icon {
				margin-left: 0.5em;
			}
		}
	}
`;