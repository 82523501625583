import PNTrading from 'src/pn-trading-sdk';
import { useEffect, useState, useRef, useContext } from 'react';
import { Context } from '../../App';
import { getBiddingFee } from '../Util';
import API from './API';

export default class Invoices {
	static async list(params = {}) {
		let user = params.user || 'all';
		delete params.user;
		return await API.get(
			`/payments?user=${user}&${Object.keys(params)
				.filter((k) => params[k] != null && params[k] != '')
				.map((key) => `${key}=${params[key]}`)
				.join('&')}`
		);
	}

	static async get(id) {
		return await API.get(`/payments/${id}`);
	}

	static async create(data) {
		return await API.post(`/payments`, data);
	}

	static async delete(id) {
		return await API.delete(`/payments/${id}`);
	}
}

export function useInvoice(id) {
	const [invoice, setInvoice] = useState(); // invoice object
	const [newSeller, setNewSeller] = useState(); // new seller object
	const [rows, setRows] = useState([]); // invoice rows

	const hasChangedRows = useRef(false); // has changed rows
	const updatedInvoice = useRef(false); // has updated invoice

	const { setLoading } = useContext(Context);

	useEffect(async () => {
		let invoice = await PNTrading.get(`/payments/${id}?user=all`); // get invoice
		setInvoice(invoice);
		setRows(invoice.rows);
	}, [id]);

	let rowsPrice = rows?.reduce((tot, r) => (isNaN(r?.amount) ? tot : tot + Number(r?.amount)), 0); // sum of rows

	// sum the objects price excluding the objects that has the removed key defined.
	let price = invoice?.objects?.reduce((tot, o) => (o.removed != undefined ? tot : tot + Number(o?.amount)), 0);

	let nonTaxableSubTotal = invoice?.objects?.reduce((tot, o) => (o.removed != undefined || o.nonTaxable != true ? tot : tot + Number(o?.amount)), 0);

	let subtotal = price + rowsPrice;
	let tax = (subtotal - nonTaxableSubTotal) * 0.25;
	let total = Math.ceil(subtotal + tax);

	useEffect(async () => {
		if (hasChangedRows.current) {
			setLoading(true);
			await PNTrading.put(`/payments/${id}?user=all`, { rows });
			setLoading(false);
		}
	}, [rows]);

	useEffect(() => {
		let tid = setTimeout(async () => {
			if (!updatedInvoice.current) return;
			setLoading(true);

			let i = { ...invoice };

			delete i.hash;
			delete i.time_created;
			delete i.payed_date;
			delete i.id;
			delete i.amount;
			delete i.user;

			await PNTrading.put(`/payments/${id}?user=all`, i);
			updatedInvoice.current = false;

			setLoading(false);
		}, 1000);

		return () => clearTimeout(tid);
	}, [invoice]);

	const update = async (key, value) => {
		updatedInvoice.current = true;
		setInvoice((i) => {
			let newInvoice = { ...i };
			newInvoice[key] = value;
			return newInvoice;
		});
	};

	const getBuyerData = (key) => invoice.buyer[key] ?? invoice.user[key] ?? invoice.user.meta?.[key] ?? '';

	const isValid = () => {
		if (getBuyerData('org_nr') == '' || getBuyerData('first_name') == '' || getBuyerData('last_name') == '' || getBuyerData('address') == '' || getBuyerData('zip') == '' || getBuyerData('phone') == '') return false;

		return true;
	};

	const updateInvoiceObject = async (objectID, key, value) => {
		let objects = invoice.objects.map((o) => {
			if (o.id != objectID) return o;
			if (o.id == objectID) return { ...o, [key]: value };
		});
		setInvoice((i) => ({ ...i, objects }));
		await PNTrading.put(`/payments/${id}?user=all`, { objects });
	};

	const disableObject = async (objectID) => {
		updateInvoiceObject(objectID, 'removed', {
			time: new Date().getTime(),
			by: invoice.user.id,
		});
		setTimeout(() => updateFee(), 0);
	};

	const enableObject = async (objectID) => {
		updateInvoiceObject(objectID, 'removed', undefined);
		setTimeout(() => updateFee(), 0);
	};

	const setObjectTaxStatus = async (objectID, nonTaxable) => {
		updateInvoiceObject(objectID, 'nonTaxable', nonTaxable);
	};

	const updateFee = () => {
		setInvoice((invoice) => {
			setRows((rows) => {
				let newRows = [...rows];
				newRows.forEach((r) => {
					if (r.title == 'Serviceavgift') {
						r.amount = invoice.objects.reduce((tot, o) => (o.removed != undefined ? tot : tot + getBiddingFee(Number(o.amount))), 0);
					}
				});

				return newRows;
			});

			return invoice;
		});
	};

	const addRow = async (e) => {
		hasChangedRows.current = true;
		e.preventDefault();
		let formData = new FormData(e.target);
		setRows((r) => [...r, { ...Object.fromEntries(formData.entries()), seller: newSeller }]);
		e.target.reset();
		setNewSeller(null);
	};

	const removeRow = (row) => {
		hasChangedRows.current = true;
		setRows((rows) => rows.filter((r) => r != row));
	};

	const setObjectAmount = (objectID, amount) => {
		updateInvoiceObject(objectID, 'amount', Number(amount));
	};

	return {
		...invoice,
		rows,
		price,
		nonTaxableSubTotal,
		subtotal,
		tax,
		total,
		addRow,
		removeRow,
		newSeller,
		setNewSeller,
		disableObject,
		enableObject,
		setObjectTaxStatus,
		update,
		getBuyerData,
		isValid,
		setObjectAmount,
	};
}
