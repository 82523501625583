import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Button from '../../../elements/Button';
import Popup from '../../Popup';
import { useClosePopup } from '../../../utils/hooks/usePopup';
import Select from '../../../elements/Select';

export default function MarkAsPayed({ invoice }) {
	// Payment method state, default value 'none'.
	const [paymentMethod, setPaymentMethod] = useState('none');

	const close = useClosePopup();

	const markAsPayed = async () => {
		if (paymentMethod === 'none') {
			alert('Välj betalningsmetod');
			return;
		}

		invoice.update('type', paymentMethod);
		invoice.update('status', 'paid');
		close();
	};

	return (
		<Popup close={close} width="40em" maxWidth="30em">
			<Style>
				<h2 className="title">Markera som betald?</h2>
				<div className="spacer s"></div>
				<p>
					Faktura nr. <strong>{invoice.id}</strong> kommer att markeras som betald. Detta kan inte ångras och ska enbart göras om fakturan garanterat har blivit inbetald av kunden!
				</p>

				<div className="spacer s"></div>

				{/* Payment method select input attached to the state. */}
				<select value={paymentMethod} onChange={(e) => setPaymentMethod(e.target.value)}>
					<option value="none" disabled>
						Välj betalmetod...
					</option>
					<option value="onsite_card">På plats - Kort</option>
					<option value="onsite_cash">På plats - Kontant</option>
					<option value="onsite_swish">På plats - Swish</option>
					<option value="invoice_bank">Faktura - Inbetalning Bank</option>
					<option value="invoice_swish">Faktura - Swish</option>
				</select>

				<div className="buttons">
					<Button type="secondary" onClick={close}>
						Nej
					</Button>
					<Button onClick={markAsPayed}>Ja</Button>
				</div>
			</Style>
		</Popup>
	);
}

const Style = styled.div``;
