import React from "react";
import Icon from "src/elements/Icon";
import Page from "src/layout/Page";
import EditableCustomerInvoiceCard from "src/pages/customerInvoices/components/EditableCustomerInvoiceCard";
import { updateCustomerInvoice } from "src/utils/api/customer-invoices/CustomerInvoices";
import useCustomerInvoice from "src/utils/api/customer-invoices/hooks/useCustomerInvoice";
import styled from "styled-components";

export default function PageCustomerInvoice(props: any) {
    
    const {customerInvoice, loading} = useCustomerInvoice(props.match.params.id);

    if (loading || !customerInvoice) {
        return (
            <Style>
                <div style={{ paddingTop: 100, width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <div className="loader"></div>
                </div>
            </Style>
        )
    }

    function handleSetAsSentClick() {
        if (customerInvoice !== null && customerInvoice.id !== null && window.confirm('Är du säker på att du vill markera denna faktura som skickad?')) {
            updateCustomerInvoice(customerInvoice.id, { status: 'sent' }).then(() => {
                window.location.reload();
            });
        }
    }

    return (
        <Style>
            <div className="top-wrapper hide-print">
                <div className="info">
                    <div className="status-wrapper">
                        {{
                            draft: <div className="badge gray">UTKAST</div>,
                            sent: <div className="badge green">SKICKAD</div>,
                        }[customerInvoice.status]}
                    </div>
                    <div className="spacer s"></div>
                    <h1>Kundfaktura #{customerInvoice.id}</h1>
                    <h2>{customerInvoice.customerName}</h2>
                </div>

                <div className="buttons-wrapper">
                    <button className="button" onClick={() => window.print()}>
                        <Icon>print</Icon>
                        Skriv ut
                    </button>
                    {customerInvoice.status !== 'sent' &&  <button className="button" onClick={handleSetAsSentClick}>
                        <Icon>send</Icon>
                        Markera som skickad
                    </button>}
                </div>

            </div>

            <EditableCustomerInvoiceCard invoiceID={Number(customerInvoice.id)}/>

        </Style>
    )
}

const Style = styled(Page)`

    .top-wrapper {
        grid-column: 1 / -1;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;

        h2 {
            font-size: 18px;
            color: #666;
        }

        .buttons-wrapper {
            display: flex;
            gap: 10px;

        }
    }
`;