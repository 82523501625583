import PNTrading from 'src/pn-trading-sdk';
import React from 'react';
import Icon from '../../elements/Icon';
import usePopup from '../../utils/hooks/usePopup';

export default function ShowPaymentQRButton({ invoice }) {

	if (!invoice) return null;

	return (
		<div
			className="icon-button"
			onClick={async () => {
				if (!invoice.isValid()) {
					alert('Fakturan saknar information');
					return;
				}

				const total = invoice.total;
				const message = `Betalning nr. ${invoice.id}, ${invoice.user.username}`;

				window.open(`${PNTrading.getURL()}/swish-qr?total=${total}&message=${encodeURIComponent(message)}`, '_blank').focus();
			}}
		>
			<Icon>qr_code</Icon>
			<span>Visa swish kod</span>
		</div>
	);
}
