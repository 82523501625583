import React from 'react';
import styled from 'styled-components';

export default function Checkbox({ label, ...rest }) {
	return (
		<Style className="checkbox-container container">
			{label}
			<input type="checkbox" {...rest} />
			<span className="checkmark"></span>
		</Style>
	);
}

const Style = styled.label`
	font-size: 1.5rem;

	* {
		font-size: 1em;
	}

	/* Customize the label (the container) */
	display: flex;
	align-items: center;
	position: relative;
	padding-left: 1.5em;
	cursor: pointer;
	user-select: none;

	/* Hide the browser's default checkbox */
	input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
		height: 0;
		width: 0;
	}

	/* Create a custom checkbox */
	.checkmark {
		position: absolute;
		top: 50%;
		left: 0;
		height: 1em;
		width: 1em;
		border-radius: 0.35rem;
		overflow: hidden;
		transform: translateY(-50%);
		background-color: #eee;
	}

	/* On mouse-over, add a grey background color */
	&:hover input ~ .checkmark {
		background-color: #ccc;
	}

	/* When the checkbox is checked, add a blue background */
	& input:checked ~ .checkmark {
		background-color: #2196f3;
	}

	& input:disabled ~ .checkmark {
		cursor: not-allowed;
		background-color: #eee !important;
		filter: grayscale(100%);
	}

	/* Create the checkmark/indicator (hidden when not checked) */
	.checkmark:after {
		content: '';
		position: absolute;
		display: none;
	}

	/* Show the checkmark when checked */
	& input:checked ~ .checkmark:after {
		display: block;
	}

	/* Style the checkmark/indicator */
	& .checkmark:after {
		left: 0.3em;
		top: 0.1em;
		width: 0.25em;
		height: 0.5em;
		border: solid white;
		border-width: 0 0.15em 0.15em 0;
		transform: rotate(45deg);
	}
`;
