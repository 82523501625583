import React, { useContext, useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import styled from 'styled-components';
import { Context } from '../App';
import API from '../utils/api/API';

export default function Login() {
	const { load } = useContext(Context);

	const [name, setName] = useState('');
	const [password, setPassword] = useState('');
	const [redirect, setRedirect] = useState(API.isSignedIn());
	const [status, setStatus] = useState();

	const login = async () => {
		let login = await API.login(name, password);

		if (login == 'user_not_found') return setStatus({ message: 'User not found' });
		if (login == 'invalid_password') return setStatus({ message: 'Invalid password!' });

		if (login === true) {
			load();
			setRedirect(true);
		}
	};

	useEffect(() => {
		let tid = setTimeout(() => setStatus(undefined), 5000);
		return () => clearTimeout(tid);
	}, [status]);

	if (redirect) return <Redirect to="/"></Redirect>;

	return (
		<LoginStyle>
			<div className="card">
				<h2>Logga in</h2>

				<label htmlFor="name">Namn</label>
				<input type="text" name="name" id="name" onChange={(e) => setName(e.target.value)} />

				<label htmlFor="password">Lösenord</label>
				<input type="password" name="password" id="password" onChange={(e) => setPassword(e.target.value)} />
				<button onClick={() => login()} id="login-btn">
					Login
				</button>

				{status && <p>{status.message}</p>}
			</div>
		</LoginStyle>
	);
}

const LoginStyle = styled.div`
	background: #dff2ff;
	width: 100%;
	height: 100vh;

	.card {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		display: flex;
		flex-direction: column;

		label {
			margin-top: 1em;
		}

		#login-btn {
			margin-top: 1em;
		}
	}
`;
