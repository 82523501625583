import React, { useEffect, useState } from 'react';
import Page from '../../layout/Page';
import Card from '../../layout/Card';
import styled from 'styled-components';
import PNTrading from 'src/pn-trading-sdk';
import { formatPrice, getPaymentGroup } from '../../utils/Util';
import { Link } from 'react-router-dom';

export default function PageAuctionSummary(props) {
	let id = props.match.params.id;

	const [objects, setObjects] = useState();
	const [auction, setAuction] = useState();
	const [payments, setPayments] = useState();

	useEffect(() => {
		(async () => {
			let paymentsResp = await PNTrading.get(`/payments?user=all&auction=${id}&limit=10000&embed=true`);
			setPayments(paymentsResp.data);
			
			let objects = await PNTrading.get(`/auctions/${id}/objects?limit=10000&embed=true&noCache=true`);
			
			const getPaymentByObject = (o) => {
				return paymentsResp.data.find((p) => p.objects.find((oo) => oo.id == o.id) != undefined);
			};
			
			let sellers = (await PNTrading.get(`/sellers?noCache=true&limit=10000`)).data

			for (let object of objects.data) {
				object.payment = getPaymentByObject(object)
				object.sellerData = sellers.find(s => s.id == object.seller)
			}

			setObjects(objects.data)
		})()

		PNTrading.get(`/auctions/${id}?noCache=true`).then(setAuction);
	}, [id]);

	if (!objects || !auction || !payments)
		return (
			<div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
				<div className="loader"></div>
			</div>
		);

	const objectIsRemoved = o => (o.payment && o.payment.objects?.find(oo => oo.id == o.id)?.removed) != undefined

	let paidPayments = payments.filter((payment) => payment.status === 'paid');

	let wonObjects = objects.filter((o) => o.ending_data != null);
	let paidObjects = wonObjects.filter((o) => (o.payment != undefined && o.payment.status == 'paid') && !objectIsRemoved(o));
	let notPaidObjects = wonObjects.filter((o) => (o.payment == undefined || o.payment.status != 'paid') || objectIsRemoved(o));

	const totalBids = wonObjects.reduce((tot, o) => tot + Number(o.ending_data.lastBid.amount), 0);
	const paidBids = paidObjects.reduce((tot, o) => tot + Number(o.ending_data.lastBid.amount), 0);

	const paymentsTotal = paidPayments.reduce((tot, o) => tot + Number(o.total), 0);

	const objectRow = (o) => (
		<tr key={o.id}>
			<td>
				{o.position} / {o.id}
			</td>
			<td>{o.title}</td>
			<td>{o.sellerData?.name}</td>
			<td>{formatPrice(o.ending_data.lastBid.amount)}</td>
			<td>{o.payment && o.payment.type ? getPaymentGroup(o.payment) : '-'}</td>
			<td>{o.payment && <Link to={`/invoices/${o.payment.id}`}>{o.payment.id}</Link>}</td>
		</tr>
	);

	return (
		<Style>
			<Card size="medium" className="hide-print">
				<h2>Auktionssummering</h2>
				<h5>(denna ruta visas inte vid utskrift)</h5>
				<div className="spacer s"></div>
				<p>Denna sida kan skrivas ut direkt i webbläsaren, antingen som en PDF eller via skrivare. Summeringen baseras på inbetalningar och avprickade objekt.</p>
				<div className="spacer s"></div>
				<button onClick={() => window.print()}>Skriv ut</button>
			</Card>

			<Card size="full" className="scale-down-mobile">
				<h2>
					<strong>{auction.name}</strong>
				</h2>
				<div className="spacer m"></div>

				<h4>Betalda objekt ({paidObjects.length} st)</h4>
				<div className="spacer xs"></div>
				<table className="objects-table">
					{tableHead()}
					<tbody>
						{paidObjects.map(objectRow)}
					</tbody>
				</table>

				<div className="spacer s"></div>

				<table className="totals">
					<tbody>
						<tr>
							<th>Bud (hela auktionern):</th>
							<td></td>
							<td>{formatPrice(totalBids)}</td>
						</tr>

						<tr>
							<th>Bud (betalade objekt):</th>
							<td></td>
							<td>{formatPrice(paidBids)}</td>
						</tr>

						<tr>
							<th>Totalt i kassan (inkl. moms):</th>
							<td></td>
							<td>{formatPrice(paymentsTotal)}</td>
						</tr>

						<tr className="sub-row">
							<td></td>
							<th>Swish:</th>
							<td>{formatPrice(paidPayments.filter((p) => getPaymentGroup(p) == 'Swish').reduce((tot, o) => tot + Number(o.total), 0))}</td>
						</tr>

						<tr className="sub-row">
							<td></td>
							<th>Kort:</th>
							<td>{formatPrice(paidPayments.filter((p) => getPaymentGroup(p) == 'Kort').reduce((tot, o) => tot + Number(o.total), 0))}</td>
						</tr>

						<tr className="sub-row">
							<td></td>
							<th>Kontant:</th>
							<td>{formatPrice(paidPayments.filter((p) => getPaymentGroup(p) == 'Kontant').reduce((tot, o) => tot + Number(o.total), 0))}</td>
						</tr>

						<tr className="sub-row">
							<td></td>
							<th>Bank:</th>
							<td>{formatPrice(paidPayments.filter((p) => getPaymentGroup(p) == 'Bank').reduce((tot, o) => tot + Number(o.total), 0))}</td>
						</tr>

						<tr>
							<th>Varav serviceavgift (inkl. moms):</th>
							<td></td>
							<td>{formatPrice(paidPayments.reduce((tot, o) => tot + Number(o.rows.find((r) => r.title == 'Serviceavgift') ? o.rows.find((r) => r.title == 'Serviceavgift').amount : 0), 0) * 1.25)}</td>
						</tr>
					</tbody>
				</table>

				{notPaidObjects.length != 0 && (
					<>
						<div className="spacer l"></div>
						<h4>Ej betalade objekt ({notPaidObjects.length} st)</h4>
						<div className="spacer xs"></div>
						<table className="objects-table">
							{tableHead()}
							<tbody>
								{notPaidObjects.map(objectRow)}
							</tbody>
						</table>
					</>
				)}
			</Card>
		</Style>
	);
}

const tableHead = () => (
	<thead>
		<tr>
			<th>ID</th>
			<th>Namn</th>
			<th>Säljare</th>
			<th>Bud</th>
			<th>Betalmetod</th>
			<th>Betalning ID</th>
		</tr>
	</thead>
);

const Style = styled(Page)`
	.objects-table {
		background-color: White;
		font-size: 12px;
		width: 100%;
		border-collapse: collapse;

		th {
			padding: 3px 10px;
			text-align: left;
		}

		td {
			padding: 3px 10px;
			border-color: #666666;
			border-width: 1px;
			border-style: Solid;
		}
	}

	table.totals {
		background-color: White;
		font-size: 12px;
		border-collapse: collapse;

		margin-left: auto;

		th:last-child,
		td:last-child {
			padding-right: 10px;
		}

		th {
			padding: 3px 20px;
			text-align: left;
		}

		td {
			padding: 3px 20px;
		}

		.sub-row {
			th {
				font-weight: 200;
			}
		}
	}
`;
