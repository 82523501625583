import React from 'react';
import { useContext } from 'react/cjs/react.production.min';
import styled from 'styled-components';
import { Context } from '../../App';
import Card from '../../layout/Card';
import ResetBuyerData from '../../layout/popups/invoice/ResetBuyerData';
import usePopup from '../../utils/hooks/usePopup';

export default function InvoiceInfoCard({ invoice }) {
	const updateField = (e, key) => invoice.update('buyer', { ...invoice.buyer, [key]: e.target.value });

	const [open] = usePopup();

	if (invoice.status != 'paid') return null;

	return (
		<Style size="medium">
			<div className="row center">
				<div className="h3">Fakturainformation</div>
			</div>
			<div className="spacer s"></div>

			{invoice.status == 'paid' && (
				<>
					<div className="spacer s"></div>

					<div className="input-column">
						<label>Betaldatum</label>
						<div className="spacer xs"></div>
						<input disabled={true} type="date" value={new Date(invoice.payed_date).getDateString()} />
					</div>

					<div className="spacer s"></div>

					<div className="input-column">
						<label>Betalmetod</label>
						<div className="spacer xs"></div>
						<input
							disabled={true}
							type="text"
							value={
								{
									null: '-',
									onsite_card: 'På plats - Kort',
									onsite_cash: 'På plats - Kontant',
									onsite_swish: 'På plats - Swish',
									invoice_bank: 'Faktura - Inbetalning Bank',
									invoice_swish: 'Faktura - Swish',
								}[invoice.type]
							}
						/>
					</div>
				</>
			)}
		</Style>
	);
}

const Style = styled(Card)``;
