import React from 'react';
import { useContext } from 'react/cjs/react.production.min';
import styled from 'styled-components';
import { Context } from '../../App';
import Card from '../../layout/Card';
import ResetBuyerData from '../../layout/popups/invoice/ResetBuyerData';
import usePopup from '../../utils/hooks/usePopup';

export default function BuyerCard({ invoice }) {
	const updateField = (e, key) => invoice.update('buyer', { ...invoice.buyer, [key]: e.target.value });

	const [open] = usePopup();

	const editable = true;

	return (
		<Style size="medium">
			<div className="row center">
				<div className="h3">Köpare</div>
				<div className="spacer ws"></div>
				{editable && (
					<button disabled={!editable} onClick={() => open(<ResetBuyerData invoice={invoice} />)}>
						Återställ
					</button>
				)}
			</div>
			<div className="spacer s"></div>

			<div className="input-column">
				<label>{invoice.user.account_type == 'private' ? 'Personnummer' : 'Org. Nr'}</label>
				<div className="spacer xs"></div>
				<input disabled={!editable} type="text" value={invoice.getBuyerData('org_nr')} placeholder={invoice.user.account_type == 'private' ? 'YYMMDD-XXXX' : 'XXXXXX-XXXX'} onChange={(e) => updateField(e, 'org_nr')} />
			</div>

			<div className="spacer s"></div>

			<div className="input-column">
				<label>Namn</label>
				<div className="spacer xs"></div>
				<div className="row">
					<input disabled={!editable} type="text" value={invoice.getBuyerData('first_name')} onChange={(e) => updateField(e, 'first_name')} />
					<div className="spacer wxs"></div>
					<input disabled={!editable} type="text" value={invoice.getBuyerData('last_name')} onChange={(e) => updateField(e, 'last_name')} />
				</div>
			</div>

			<div className="spacer s"></div>

			<div className="input-column">
				<label>Adress</label>
				<div className="spacer xs"></div>
				<input disabled={!editable} type="text" placeholder="Adress" value={invoice.getBuyerData('address')} onChange={(e) => updateField(e, 'address')} />

				<div className="spacer xs"></div>
				<div className="row">
					<input disabled={!editable} type="text" placeholder="Postnummer" value={invoice.getBuyerData('zip')} onChange={(e) => updateField(e, 'zip')} />
					<div className="spacer wxs"></div>
					<input disabled={!editable} type="text" placeholder="Stad" value={invoice.getBuyerData('city')} onChange={(e) => updateField(e, 'city')} />
				</div>
			</div>

			<div className="spacer s"></div>

			<div className="input-column">
				<label>Telefonnummer</label>
				<div className="spacer xs"></div>
				<input disabled={!editable} type="text" value={invoice.getBuyerData('phone')} onChange={(e) => updateField(e, 'phone')} />
			</div>

			<div className="spacer m"></div>

			<div className="input-column">
				<label>Företagsnamn</label>
				<div className="spacer xs"></div>
				<input disabled={!editable} type="text" value={invoice.getBuyerData('company_name')} onChange={(e) => updateField(e, 'company_name')} />
			</div>
		</Style>
	);
}

const Style = styled(Card)``;
