import React, { useState } from 'react';
import styled from 'styled-components';
import { useClosePopup } from '../../../utils/hooks/usePopup';
import Invoices from '../../../utils/api/Invoices';
import Button from '../../../elements/Button';
import Popup from '../../../layout/Popup';

export default function CreatePayment({ defaultUser = null, onCreated }) {
	const close = useClosePopup();

	const [user, setUser] = useState(defaultUser)

	const confirm = async () => {
		if (user == null || user === undefined) {
			alert('Användar ID saknas.')
			return;
		}

		try {
			const resp = await Invoices.create({
				user: user
			});

			if (resp === 'user_not_found') {
				alert('Användaren hittades inte.')
				return;
			}
			
			onCreated && onCreated();
			close();
		} catch (e) {
			console.error(e);
			alert('Kunde inte skapa faktura.')
		}
	};

	return (
		<Popup close={close} width="40em" maxWidth="25em">
			<Style>
				<h2 className="title">Skapa faktura</h2>
				<div className="spacer s"></div>

				{defaultUser ? (
					<>
						<p>Skapa en blank faktura för användaren {defaultUser}.</p>

						<div className="spacer s"></div>
					</>
				) : (
					<>
						<p>Skapa en blank faktura för en användare.</p>

						<div className="spacer xs"></div>

						<label htmlFor="user_id">Användar ID</label>
						<br />
						<input type="number" name="user_id" placeholder="1234" value={user} onChange={e => setUser(e.target.value)} />

						<div className="spacer s"></div>
					</>
				)}

				

				<div className="buttons">
					<Button type="secondary" onClick={close}>
						Avbryt
					</Button>
					<Button onClick={confirm}>Skapa</Button>
				</div>
			</Style>
		</Popup>
	);
}

const Style = styled.div``;
