import PNTrading from 'src/pn-trading-sdk';
import React, { useState, useEffect } from 'react';
import Select, { Option } from '../../elements/Select';

export default function SellerSelect({ defaultValueID, ...rest }) {
	let defaultValue = rest.defaultValue;

	if (defaultValue == -1) {
		defaultValue = undefined;
	}

	const [sellers, setSellers] = useState();

	useEffect(async () => {
		setSellers((await PNTrading.get(`/sellers?limit=10000`)).data);
	}, []);

	if (defaultValueID && sellers) {
		defaultValue = sellers.findIndex((c) => c.id == defaultValueID);
	}

	return (
		<Select {...rest} defaultValue={defaultValue}>
			{sellers?.map((seller) => (
				<Option key={seller.id} value={seller.id}>{`${seller.name} (${seller.org_number})`}</Option>
			))}
		</Select>
	);
}
