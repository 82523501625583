import { getSettings } from 'src/pn-trading-sdk/PNConfig';
import jwtDecode from 'jwt-decode';
import md5 from 'md5';
const sha256 = require('sha256');

const CACHE_TTL = 60 * 5;

/**
 * General API class that contains and handles all requests to the external API.
 */
export default class API {
	static getURL() {
		return getSettings().PN_SDK_REST_URL;
	}

	static getCached(ep) {
		let key = Object.keys(localStorage).find((ls) => ls.startsWith('cache_') && ls.includes(ep));

		if (!key) return undefined;

		return JSON.parse(localStorage.getItem(key));
	}

	static updateCache(ep, value) {
		let key = Object.keys(localStorage).find((ls) => ls.startsWith('cache_') && ls.includes(ep));

		localStorage.setItem(key, JSON.stringify(value));
	}

	static clearCache(ep = undefined) {
		let keys = Object.keys(localStorage).filter((ls) => (ep == undefined ? ls.startsWith('cache_') : ls.startsWith('cache_') && ls.includes(ep)));
		keys.map((key) => localStorage.removeItem(key));
	}

	static async request(method, ep, body, noCache = false) {
		let cacheKey = 'cache_' + method + '_' + ep + '_' + md5(ep + JSON.stringify(body));
		let result = localStorage.getItem(cacheKey);

		noCache = true;

		if (result && !noCache) {
			result = JSON.parse(result);
			if (result && result.time) {
				if (new Date().getTime() - result.time <= CACHE_TTL * 1000) {
					return result.result;
				}
			}
		}

		let response = await fetch(`${getSettings().PN_SDK_REST_URL}${ep}`, {
			method: method,
			body: JSON.stringify(body),
			headers: {
				'Content-Type': 'application/json',
				authorization: `Bearer ${localStorage.getItem('usertoken')}`,
			},
		});

		let { status } = response;

		result = await response.json();

		if (!noCache) {
			localStorage.setItem(
				cacheKey,
				JSON.stringify({
					result,
					time: new Date().getTime(),
				})
			);
		}

		return result;
	}

    static currentRequests = {};
    static async get(ep, noCache = false) {
		console.log(ep, this.currentRequests)
        this.currentRequests[ep] = this.currentRequests[ep] || this.request('GET', ep, undefined, noCache);
        const response = await this.currentRequests[ep];
        delete this.currentRequests[ep];
        return response;
    }

	static async post(ep, body, noCache = true) {
		return await this.request('POST', ep, body, noCache);
	}

	static async put(ep, body, noCache = true) {
		return await this.request('PUT', ep, body, noCache);
	}

	static async delete(ep, body) {
		return await this.request('DELETE', ep, body, true);
	}

	static getUserData() {
		if (this.isSignedIn()) {
			return jwtDecode(localStorage.getItem('usertoken'));
		} else {
			return {};
		}
	}

	static getUserToken() {
		return localStorage.getItem('usertoken');
	}

	static async login(username, password) {
		let user = await this.post(`/login`, { username, password: sha256(password) }, true);

		if (user == 'user_not_found') return user;
		if (user == 'invalid_password') return user;

		if (!jwtDecode(user)) return user;

		let decoded = jwtDecode(user);
		if (!decoded.permissions?.split(',').find(perm => {
			if (perm == 'admin') return true;
			if (perm == 'developer_admin') return true;
			if (perm == 'manage_auctions') return true;
			if (perm == 'manage_users') return true;
			if (perm == 'web_admin') return true;
		})) return false;

		localStorage.setItem('usertoken', user);
		return true;
	}

	/**
	 * @returns true if logged in user has any permission in the array.
	 */
	static lockTo(permissions) {
		if (!localStorage.getItem('usertoken')) return false;
		return (
			this.getUserData()
				?.permissions?.split(',')
				.find((p) => permissions.includes(p)) != undefined
		);
	}

	/**
	 * Check if the user has a stored user token in local storage.
	 * @returns Boolean
	 */
	static isSignedIn() {
		if (localStorage.getItem('usertoken')) return true;
		else return false;
	}
}
