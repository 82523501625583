import { useEffect, useState } from "react";
import { CustomerInvoice, GetBillableSellersArgs, getBillableSellers } from "src/utils/api/customer-invoices/CustomerInvoices";

export default function useBillableSellers(args?: GetBillableSellersArgs) {

    const [billableSellers, setBillableSellers] = useState<CustomerInvoice[]>([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        (async () => {
            setBillableSellers(await getBillableSellers(args));
            setLoading(false);
        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [args?.after, args?.before]);

    return {
        billableSellers,
        loading
    }
}