import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Button from '../../../elements/Button';
import Popup from '../../Popup';
import { useClosePopup } from '../../../utils/hooks/usePopup';

export default function ResetBuyerData({ invoice }) {
	const close = useClosePopup();

	const removeObject = async () => {
		invoice.update('buyer', {});
		close();
	};

	return (
		<Popup close={close} width="40em" maxWidth="30em">
			<Style>
				<h2 className="title">Återställ information?</h2>
				<div className="spacer s"></div>
				<p>
					Dessa fält kommer att rensas och informationen kommer hämtas från användaren <strong>{invoice.user.username}</strong>
				</p>

				<div className="buttons">
					<Button type="secondary" onClick={close}>
						Nej
					</Button>
					<Button onClick={removeObject}>Ja</Button>
				</div>
			</Style>
		</Popup>
	);
}

const Style = styled.div``;
