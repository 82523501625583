import React from 'react';
import Checkbox from '../../elements/Checkbox';
import Icon from '../../elements/Icon';
import styled from 'styled-components';
import SellerSelect from '../sellers/SellerSelect';
import { useSellers } from '../../utils/api/Sellers';
import TransferObjectButton from './TransferObjectButton';

export default function InvoiceTable({ invoice }) {
	const { sellers } = useSellers();

	function handleObjectAmountUpdate(object) {

		let timeoutID = null;

		return (e) => {
			let amount = e.target.value;
			if (amount == '') amount = 0;

			if (timeoutID) clearTimeout(timeoutID);

			// Debounce onChange.
			timeoutID = setTimeout(() => {
				invoice.setObjectAmount(object.id, amount);
			}, 500);
		};
	}

	return (
		<Style onSubmit={invoice.addRow}>
			<table className="meta-table">
				<thead>
					<tr>
						<th></th>
						<th>ID</th>
						<th>Säljare</th>
						<th>Artikel</th>
						<th>Summa</th>
						<th>Momsfri</th>
						<th></th>
					</tr>
				</thead>
				<tbody>
					{invoice.objects?.map((object) => {

						if (object.data === undefined) {
							return (
								<tr key={object.id} className={`${object.removed ? 'removed' : ''}`}>
									<td className="always-visible">
										<Checkbox
											checked={!object.removed}
											onChange={(e) => {
												if (e.target.checked == false) invoice.disableObject(object.id);
												else invoice.enableObject(object.id);
											}}
										/>
									</td>
									<input type="text" value={`${object.id}`} disabled />
									<td>-</td>
									<td>
										<input disabled type="text" defaultValue={'Borttaget objekt'} />
									</td>
									<td>
										<input onChange={handleObjectAmountUpdate(object)} type="number" defaultValue={object.amount || ' - '} /> kr
									</td>
									<td></td>
									<th></th>
								</tr>
							)
						}

						return (
							<tr key={object.id} className={`${object.removed ? 'removed' : ''}`}>
								<td className="always-visible">
									<Checkbox
										checked={!object.removed}
										onChange={(e) => {
											if (e.target.checked == false) invoice.disableObject(object.id);
											else invoice.enableObject(object.id);
										}}
									/>
								</td>
								<td>
									<input type="text" value={`${object.data.position} / ${object.id}`} disabled />
								</td>
								<td>{object.data?.seller ? sellers?.find((c) => c.id == object.data.seller)?.name : '-'}</td>
								<td>
									<input disabled type="text" defaultValue={object.data?.title} />
								</td>
								<td>
									<input onChange={handleObjectAmountUpdate(object)} type="number" defaultValue={object.amount || ' - '} /> kr
								</td>

								<td>
									<Checkbox
										checked={object.nonTaxable || false}
										onChange={(e) => {
											invoice.setObjectTaxStatus(object.id, e.target.checked);
										}}
									/>
								</td>
								<th>
									<TransferObjectButton invoice={invoice} object={object} />
								</th>
							</tr>
						);
					})}

					{invoice.rows.map((row) => {
						return (
							<tr key={JSON.stringify(row)}>
								<td></td>
								<td>
									<input type="text" value={row.id} disabled />
								</td>
								<td>{row.seller ? sellers?.find((c) => c.id == row.seller)?.name : '-'}</td>
								<td>
									<input disabled type="text" defaultValue={row.title} />
								</td>
								<td>
									<input disabled type="number" defaultValue={row.amount} /> kr
								</td>
								<th></th>
								<td>
									<Icon onClick={() => invoice.removeRow(row)}>close</Icon>
								</td>
							</tr>
						);
					})}

					<tr>
						<td>
							<div style={{ height: '1rem' }}></div>
						</td>
					</tr>

					<tr>
						<td></td>
						<td>
							<input name="id" type="text" />
						</td>
						<td>
							<SellerSelect
								defaultValue={(() => {
									let index = sellers?.findIndex((c) => c.id == invoice.newSeller);
									return index == -1 ? undefined : index;
								})()}
								onSelect={(v) => invoice.setNewSeller(v.value)}
							/>
						</td>
						<td>
							<input name="title" type="text" />
						</td>
						<td>
							<input name="amount" type="number" /> kr
						</td>
						<th></th>
						<td>
							<button type="submit">Lägg till</button>
						</td>
					</tr>

					<tr>
						<td>
							<div style={{ height: '1rem' }}></div>
						</td>
					</tr>

					<tr>
						<td></td>
						<td></td>
						<td></td>
						<td>Summa:</td>
						<td>
							<input type="number" disabled value={invoice.subtotal} /> kr
						</td>
					</tr>

					<tr>
						<td></td>
						<td></td>
						<td></td>
						<td>Moms:</td>
						<td>
							<input type="number" disabled value={invoice.tax} /> kr
						</td>
					</tr>

					<tr>
						<td></td>
						<td></td>
						<td></td>
						<td>Att betala:</td>
						<td>
							<input type="number" disabled value={invoice.total} /> kr
						</td>
					</tr>
				</tbody>
			</table>
		</Style>
	);
}

const Style = styled.form`
	.meta-table {
		tbody {
			tr {
				td {
					&:nth-child(3) {
						input {
							width: 30rem;
						}
					}
				}

				&.removed {
					td:not(.always-visible) {
						opacity: 0.3;

						&,
						* {
							pointer-events: none;
						}
					}
				}
			}
		}
	}
`;
